import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FilterListIcon from "@material-ui/icons/FilterList";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminAppBar from "../../admin/components/AdminAppBar";
import AdminToolbar from "../../admin/components/AdminToolbar";
import SelectToolbar from "../../core/components/SelectToolbar";
import { useSnackbar } from "../../core/contexts/SnackbarProvider";
import TransactionDialog from "../components/TransactionDialog";
import TransactionTable from "../components/TransactionTable";
import { useUpdateTransaction as useUpdateTransaction } from "../hooks/useUpdateTransaction";
import { useTransactions, useTotalTransactions } from "../hooks/useTransactions";
import { TransactionData, TransactionResponse, TransactionType } from "../../admin/types/transactions";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";

const TransactionManagement = () => {
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();

  const [openTransactionDialog, setOpenTransactionDialog] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [transactionUpdated, setTransactionUpdated] = useState<TransactionResponse | undefined>(undefined);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);  // Or any other default value
  // Handle page and rowsPerPage changes
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);  // Reset to the first page when changing rows per page
  };

  const { isUpdating, updateTransaction } = useUpdateTransaction();

  const processing = isUpdating;

  const handleUpdateTransaction = async (transaction: TransactionData) => {
    updateTransaction(transaction)
      .then(() => {
        snackbar.success(
          t("transactionManagement.notifications.updateSuccess", {
            transaction: `${transaction.amount} updated`,
          })
        );
        setOpenTransactionDialog(false);
      })
      .catch(() => {
        snackbar.error(t("common.errors.unexpected.subTitle"));
      });
  };

  const handleCancelSelected = () => {
    setSelected([]);
  };

  const handleCloseUserDialog = () => {
    setTransactionUpdated(undefined);
    setOpenTransactionDialog(false);
  };

  const handleOpenUserDialog = (transaction?: TransactionResponse) => {
    setTransactionUpdated(transaction);
    setOpenTransactionDialog(true);
  };

  const handleSelectedChange = (newSelected: string[]) => {
    setSelected(newSelected);
  };


  // Filter dropdown code
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<TransactionType | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to render MenuItems
  const renderFilterMenuItem = (value: TransactionType | null, label: string) => {
    const isSelected = filter === value;
    
    return (
      <MenuItem 
        onClick={() => { handleClose(); setFilter(value); }}
        selected={isSelected}  // Apply 'selected' property based on filter
      >
        {t(label)}
      </MenuItem>
    );
  }

  // Actual getting of data
  const { data: transactions } = useTransactions(page, rowsPerPage, {type: filter});
  const { data: totalTransactions } = useTotalTransactions({type: filter});

  return (
    <React.Fragment>
      <AdminAppBar>
        {!selected.length ? (
          <AdminToolbar title={t("transactionManagement.toolbar.title")}>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" style={{ color: filter ? theme.palette.primary.main : theme.palette.text.primary }}>
                {t("transactionManagement.toolbar.filter.title")}
              </Typography>
              <IconButton
                aria-label="filter list"
                color={filter ? "primary" : "default"}  // Change color based on filter
                disabled={processing}
                onClick={handleClick}
              >
                <FilterListIcon />
              </IconButton>

            </Box>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {renderFilterMenuItem(null, "transactionManagement.toolbar.filter.all")}
              {renderFilterMenuItem(TransactionType.Withdrawal, "transactionManagement.toolbar.filter.onlyWithdrawals")}
              {renderFilterMenuItem(TransactionType.Deposit, "transactionManagement.toolbar.filter.onlyDeposits")}
            </Menu>
          </AdminToolbar>
        ) : (
          <SelectToolbar
            processing={processing}
            onCancel={handleCancelSelected}
            onDelete={()=>{}}
            selected={selected}
            enableDelete={false}
          />
        )}
      </AdminAppBar>
      <TransactionTable
        processing={processing}
        onEdit={handleOpenUserDialog}
        onSelectedChange={handleSelectedChange}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        selected={selected}
        transactions={transactions}
        checkboxes={true}
        actions={true}
        pagination={true}
        page={page}
        rowsPerPage={rowsPerPage}
        totalCount={totalTransactions!}
      />
      {//{openTransactionDialog && (
        //<TransactionDialog
          // onAdd={handleAddUser}
          // onClose={handleCloseUserDialog}
          // onUpdate={handleUpdateTransaction}
          // open={openTransactionDialog}
          // processing={processing}
          // user={transactionUpdated}
        ///>
      //)}
      }
    </React.Fragment>
  );
};
export default TransactionManagement;
