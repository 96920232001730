import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Divider } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { useTranslation, Trans } from "react-i18next";

const SuccessStatus = () => <CheckCircleOutlineIcon htmlColor='#41902e' style={{ fontSize: 180 }} />;
const CloseStatus = () => <CancelOutlinedIcon color='disabled' style={{ fontSize: 180 }} />;
const FailStatus = () => <HighlightOffOutlinedIcon color='error' style={{ fontSize: 180 }} />;

const TransactionStatusPage = () => {
  const { t } = useTranslation();
  const { merchantName, amount, type, paymentId } = useParams();

  const StatusComponent = (() => {
    switch (type) {
      case 'success-deposit':
      case 'success-withdrawal':
        return SuccessStatus;
      case 'close-deposit':
      case 'close-withdrawal':
        return CloseStatus;
      case 'fail-deposit':
      case 'fail-withdrawal':
        return FailStatus;
      default:
        return null;
    }
  })();

  const statusMessage = (() => {
    switch (type) {
      case 'success-deposit':
        return t("transactionPage.description.deposit.success");
      case 'close-deposit':
        return t("transactionPage.description.deposit.close");
      case 'fail-deposit':
        return t("transactionPage.description.deposit.fail");
      case 'success-withdrawal':
        return t("transactionPage.description.withdrawal.success");
      case 'close-withdrawal':
        return t("transactionPage.description.withdrawal.close");
      case 'fail-withdrawal':
        return t("transactionPage.description.withdrawal.fail");
      default:
        return t("transactionPage.description.invalid");
    }
  })();

  const merchantMessage = (() => {
    switch (type) {
      case 'success-deposit':
      case 'close-deposit':
      case 'fail-deposit':
        return t("transactionPage.description.deposit.merchant", {merchant: merchantName});
      case 'success-withdrawal':
      case 'close-withdrawal':
      case 'fail-withdrawal':
        return t("transactionPage.description.withdrawal.merchant", {merchant: merchantName});
      default:
        return t("transactionPage.description.invalid");
    }
  })();


  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Box my={4}>
        {StatusComponent && <StatusComponent />}
      </Box>
      <Typography variant="h2">{statusMessage}</Typography>
      <Typography variant="caption" my={1}>
        {t("transactionPage.paymentId", {id: paymentId})}
      </Typography>

      <Divider variant="middle" style={{width: '100%', marginBottom: '16px', marginTop: '16px'}} />

      <Typography variant="body2" my={2} style={{fontSize: 34}}>
        <Trans i18nKey={t("transactionPage.amount", {amount: amount})} components={{ strong: <strong /> }} />
      </Typography>
      
      <Typography variant="h3">{merchantMessage}</Typography>

    </Box>
  );
};

export default TransactionStatusPage;
