export interface TransactionData {
    amount: number;
    currency: string;
}
  
export interface TransactionPollData {
    transactionId: string;
}
  
export interface TransactionResponse {
    id: string;
    customerUrl: string;
    amount: number;
    creationTime: string;
    lastChangeTime: string;
    state: TransactionState;
    type: TransactionType;
}
  
export enum TransactionState {
    Pending = 10,
    Identified = 20,
    Authorized = 30,
    Failed = 40,
    Approved = 50,
    Rejected = 60,
    Expired = 70,
}
  
export interface TransactionPollResponse {
    transactionState: TransactionState
}

export enum TransactionType {
    Withdrawal = 1,
    Deposit = 2,
}

export interface TransactionFilter {
    type: TransactionType | null;
}