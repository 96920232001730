import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TransactionTableProps } from "../types/transactionTableProps";
import { TransactionRowProps } from "../types/transactionRowProps";
import { useTheme } from "@material-ui/core/styles";
import Empty from "../../core/components/Empty";
import * as selectUtils from "../../core/utils/selectUtils";
import { format } from 'date-fns';
import { Card, CardContent, useMediaQuery } from "@material-ui/core";
import TransactionReciept from "./TransactionReciept";

interface HeadCell {
  id: string;
  label: string;
  align: "center" | "left" | "right";
}

const headCells: HeadCell[] = [
  {
    id: "id",
    align: "left",
    label: "transactionManagement.table.headers.id"
  },
  {
    id: "type",
    align: "center",
    label: "transactionManagement.table.headers.type"
  },
  {
    id: "amount",
    align: "center",
    label: "transactionManagement.table.headers.amount",
  },
  {
    id: "lastChangeTime",
    align: "center",
    label: "transactionManagement.table.headers.lastChangeTime",
  },
  {
    id: "state",
    align: "center",
    label: "transactionManagement.table.headers.state",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead({
  onSelectAllClick,
  numSelected,
  rowCount,
}: EnhancedTableProps) {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow sx={{ "& th": { border: 0 } }}>
        <TableCell sx={{ py: 0 }}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": `${t("transactionManagement.table.selectAll")}`,
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} sx={{ py: 0 }}>
            {t(headCell.label)}
          </TableCell>
        ))}
        <TableCell align="right" sx={{ py: 0 }}>
          {t("transactionManagement.table.headers.actions")}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function formatDate(dateString: string) {
  const date = new Date(dateString);
  return format(date, 'MM/dd/yyyy HH:mm');  // Or any other format
}

const TransactionRow = ({index, onCheck, onEdit, processing, selected, transaction, checkboxes, actions}: TransactionRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();  
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const labelId = `enhanced-table-checkbox-${index}`;
  const openActions = Boolean(anchorEl);
  const handleOpenActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleCloseActions();
    onEdit(transaction);
  };

  const getChipColor = (state: string): string => {
    switch (state) {
      case "Pending":
        return theme.palette.warning.dark;
      case "Identified":
        return theme.palette.info.dark;
      case "Authorized":
        return theme.palette.success.dark;
      case "Failed":
        return theme.palette.error.dark;
      case "Approved":
        return theme.palette.success.dark;
      case "Rejected":
        return theme.palette.error.dark;
      case "Expired":
        return theme.palette.grey[500];
      default:
        return theme.palette.grey[500];
    }
  };

  // TransactionState.Pending returns 10
  const translateState = (state: string): string => {
    switch (state) {
      case "Pending":
        return t("common.transactionState.pending");
      case "Identified":
        return t("common.transactionState.identified");
      case "Authorized":
        return t("common.transactionState.authorized");
      case "Failed":
        return t("common.transactionState.failed");
      case "Approved":
        return t("common.transactionState.approved");
      case "Rejected":
        return t("common.transactionState.rejected");
      case "Expired":
        return t("common.transactionState.expired");
      default:
        return t("common.errors.unexpected.title");
    }
  };

  // Common styles for the table cell
  const borderCellLeftStyle: React.CSSProperties = {
    borderTopLeftRadius: "1rem",
    borderBottomLeftRadius: "1rem",
  };

  const borderCellRightStyle: React.CSSProperties = {
    borderTopRightRadius: "1rem",
    borderBottomRightRadius: "1rem"
  };

  const checkboxCell = checkboxes ? (
    <TableCell
        padding="checkbox"
        sx={{ ...borderCellLeftStyle }}
    >
        <Checkbox
            color="primary"
            checked={selected}
            inputProps={{
                "aria-labelledby": labelId,
            }}
            onClick={() => onCheck(transaction.id)}
        />
    </TableCell>
  ) : null;

  const actionCell = actions ? (
    <TableCell
      align="right"
      sx={{ ...borderCellRightStyle }}
    >
      <IconButton
        id="transaction-row-menu-button"
        aria-label="transaction actions"
        aria-controls="trasnaction-row-menu"
        aria-haspopup="true"
        aria-expanded={openActions ? "true" : "false"}
        disabled={processing}
        onClick={handleOpenActions}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="transaction-row-menu"
        anchorEl={anchorEl}
        aria-labelledby="transaction-row-menu-button"
        open={openActions}
        onClose={handleCloseActions}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>{" "}
          {t("common.edit")}
        </MenuItem>
      </Menu>
    </TableCell>
  ) : null;


  return isSmallScreen ? (
    <Card
      // Hover effect
      sx={!actions ? {
        "&:hover": {
          backgroundColor: theme.palette.grey[700]
        }
      }: {}}
      style={{margin: "10px 0px 10px 0px"}}
      // Click event
      onClick={checkboxes ? undefined : () => handleEdit()}>
      <CardContent>
        <TransactionReciept transaction={transaction} showState={true}/>
      </CardContent>
    </Card>
    
  ) : (
    <TableRow
      aria-checked={selected}
      tabIndex={-1}
      key={transaction.id}
      selected={selected}
      sx={{ "& td": { bgcolor: "background.paper", border: 0 },
      ...(!checkboxes && {
        '&:hover': {
          '& td': { bgcolor: theme.palette.grey[700] , border: 0 },
        },
      }), }}
      onClick={checkboxes ? undefined : () => handleEdit()}
    >
      {checkboxCell}
      <TableCell 
        sx={{
          ...(checkboxes ? {} : { ...borderCellLeftStyle})
        }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <Typography component="div" variant="h6">
              {`${transaction.id}`}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {transaction.customerUrl}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="center">{transaction.type}</TableCell>
      <TableCell align="center">{transaction.amount}</TableCell>
      <TableCell align="center">{formatDate(transaction.lastChangeTime)}</TableCell>
      <TableCell align="center"
        sx={{
          ...(actions ? {} : { ...borderCellRightStyle})
        }}>
        <Chip label={translateState(transaction.state.toString())} style={{ backgroundColor: getChipColor(transaction.state.toString()), color: theme.palette.common.white }} />
      </TableCell>
      {actionCell}
    </TableRow>
  );
}

const TransactionTable = ({
  processing, 
  onEdit, 
  onSelectedChange, 
  handleChangePage, 
  handleChangeRowsPerPage, 
  selected, 
  transactions = [],
  checkboxes,
  actions,
  pagination,
  page,
  rowsPerPage,
  totalCount,
}: TransactionTableProps) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = selectUtils.selectAll(transactions);
      onSelectedChange(newSelecteds);
      return;
    }
    onSelectedChange([]);
  };

  const handleClick = (id: string) => {
    let newSelected: string[] = selectUtils.selectOne(selected, id);
    onSelectedChange(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  if (transactions.length === 0) {
    return <Empty title={t("transactionManagement.table.empty")} />;
  }

  const actionCellHeadItem = actions ? (
    <TableCell align="right" sx={{ py: 0 }}>
      {t("transactionManagement.table.headers.actions")}
    </TableCell>
    ) : null;

  return(
  <React.Fragment>
    {isSmallScreen ? (
        <Grid container direction="column" spacing={2}>
          {transactions.map((transaction, index) =>  (
            <TransactionRow
              index={index} 
              key={transaction.id} 
              transaction={transaction} 
              processing={processing}
              selected={isSelected(transaction.id)}
              onCheck={handleClick}
              onEdit={onEdit}
              checkboxes={checkboxes}
              actions={actions}
            />
          ))}
        </Grid>
      ) : (
    <TableContainer>
      <Table aria-labelledby="tableTitle" 
        sx={{ minWidth: 600, borderCollapse: "separate", borderSpacing: "0 1rem",}}
      >
      {checkboxes ? (<EnhancedTableHead numSelected={selected.length} onSelectAllClick={handleSelectAllClick} rowCount={totalCount} />) : (
        <TableHead>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align={headCell.align} sx={{ py: 0 }}>
              {t(headCell.label)}
            </TableCell>
          ))}
          {actionCellHeadItem}
        </TableHead>
      )}
      
        <TableBody>
             {transactions
               .map((transaction, index) => (
                 <TransactionRow
                   index={index}
                   key={transaction.id}
                   onCheck={handleClick}
                   onEdit={onEdit}
                   processing={processing}
                   selected={isSelected(transaction.id)}
                   transaction={transaction}
                   checkboxes={checkboxes}
                   actions={actions}
                 />
               ))}
        </TableBody>
      </Table>
    </TableContainer>
    )}
    {pagination ? (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
        onRowsPerPageChange={event => handleChangeRowsPerPage(parseInt(event.target.value, 10))}
      />
    ) : (<Box></Box>) }

  </React.Fragment>
  );
};

export default TransactionTable;
