const apiPrefix = "/api/";
export const LOGIN = apiPrefix + "Login";
export const TRANSACTIONS = apiPrefix + "Transactions";
export const TRANSACTIONS_TOTAL = TRANSACTIONS + "/total";
export const TRANSACTION_DEPOSITS = TRANSACTIONS + "/deposits";
export const TRANSACTION_WITHDRAWALS = TRANSACTIONS + "/withdrawals";
export const REJECT_TRANSACTION = "/reject";
export const APPROVE_TRANSACTION = "/approve";
export const TRANSACTION_STATS = TRANSACTIONS + "/statistics";
export const TRANSACTION_DEPOSITS_IN_PROGRESS = TRANSACTION_DEPOSITS + "/in-progress";
export const TRANSACTION_WITHDRAWAL_IN_PROGRESS = TRANSACTION_WITHDRAWALS + "/in-progress";
// Not yet impl
export const LOGOUT = apiPrefix + "logout";
export const USER_INFO = apiPrefix + "user-info";
