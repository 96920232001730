import { useTranslation } from "react-i18next";
import { TransactionResponse } from "../../admin/types/transactions";
import { Box, Chip, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import { format } from "date-fns";


function formatDate(dateString: string) {
  const date = new Date(dateString);
  return format(date, 'MM/dd/yyyy HH:mm');  // Or any other format
}

type TransactionRecieptProps = {
    transaction: TransactionResponse;
    showState: boolean;
};

const TransactionReciept = ({
  transaction,
  showState
}: TransactionRecieptProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const gridSizeItem = 2;
  const gridSizeItemValue = 10;
  const gridSizeDivider = 12;

  const getChipColor = (state: string): string => {
    switch (state) {
      case "Pending":
        return theme.palette.warning.dark;
      case "Identified":
        return theme.palette.info.dark;
      case "Authorized":
        return theme.palette.success.dark;
      case "Failed":
        return theme.palette.error.dark;
      case "Approved":
        return theme.palette.success.dark;
      case "Rejected":
        return theme.palette.error.dark;
      case "Expired":
        return theme.palette.grey[500];
      default:
        return theme.palette.grey[500];
    }
  };

  // TransactionState.Pending returns 10
  const translateState = (state: string): string => {
    switch (state) {
      case "Pending":
        return t("common.transactionState.pending");
      case "Identified":
        return t("common.transactionState.identified");
      case "Authorized":
        return t("common.transactionState.authorized");
      case "Failed":
        return t("common.transactionState.failed");
      case "Approved":
        return t("common.transactionState.approved");
      case "Rejected":
        return t("common.transactionState.rejected");
      case "Expired":
        return t("common.transactionState.expired");
      default:
        return t("common.errors.unexpected.title");
    }
  };

  const stateItem = showState ?
    <>
      <Grid item xs={gridSizeItem}>
        <Typography align="left">
          {t("transactionManagement.table.headers.state")}
        </Typography>
      </Grid>
      <Grid item xs={gridSizeItemValue}>
      <Box display="flex" justifyContent="flex-end">
        <Chip label={translateState(transaction.state.toString())} style={{ backgroundColor: getChipColor(transaction.state.toString()), color: theme.palette.common.white }} />
        </Box>
      </Grid>
      <Grid item xs={gridSizeDivider}>
        <Divider variant="middle" style={{ margin: '12px 0px' }} />
      </Grid>
    </> : null;

  return (
    <Grid container justifyContent={"space-between"} alignItems="center">
      <Grid item xs={gridSizeItem}>
        <Typography align="left">
          {t("transactionManagement.table.headers.lastChangeTime")}
        </Typography>
      </Grid>
      <Grid item xs={gridSizeItemValue}>
        <Typography align="right" variant="h4">
          {formatDate(transaction.lastChangeTime)}
        </Typography>
      </Grid>
      <Grid item xs={gridSizeDivider}>
        <Divider variant="middle" style={{ margin: '12px 0px' }}/>
      </Grid>
      <Grid item xs={gridSizeItem}>
        <Typography align="left">
          {t("transactionManagement.table.headers.id")}
        </Typography>
      </Grid>
      <Grid item xs={gridSizeItemValue}>
        <Typography align="right" variant="h4">{transaction.id}</Typography>
      </Grid>
      <Grid item xs={gridSizeDivider}>
        <Divider variant="middle" style={{ margin: '12px 0' }}/>
      </Grid>
      <Grid item xs={gridSizeItem}>
        <Typography align="left">
          {t("transactionManagement.table.headers.type")}
        </Typography>
      </Grid>
      <Grid item xs={gridSizeItemValue}>
        <Typography align="right" variant="h4">
          {transaction.type}
        </Typography>
      </Grid>
      <Grid item xs={gridSizeDivider}>
        <Divider variant="middle" style={{ margin: '12px 0' }}/>
      </Grid>
      <Grid item xs={gridSizeItem}>
        <Typography align="left">
          {t("transactionManagement.table.headers.amount")}
        </Typography>
      </Grid>
      <Grid item xs={gridSizeItemValue}>
        <Typography align="right" variant="h4">
          {transaction.amount}
        </Typography>
      </Grid>
      <Grid item xs={gridSizeDivider}>
        <Divider variant="middle" style={{ margin: '12px 0px' }}/>
      </Grid>
      {stateItem}
    </Grid>
  )
};

export default TransactionReciept;