import { useQuery } from "react-query";
import apiService from "../../services/apiService";
import { TRANSACTIONS, TRANSACTIONS_TOTAL } from "../../services/apiRoutes";
import { TransactionResponse, TransactionFilter } from "../../admin/types/transactions";

const fetchTransactions = async (page: number, rowsPerPage: number, filter: TransactionFilter | null): Promise<TransactionResponse[]> => {
  let url = `${TRANSACTIONS}?pageNumber=${page}&pageSize=${rowsPerPage}`;
  if (filter && filter.type) {
    url += `&filterType=${filter.type}`;
  }

  const { data } = await apiService.get<TransactionResponse[]>(url);
  return data;
};

export function useTransactions(page: number, rowsPerPage: number, filter: TransactionFilter | null ) {
  return useQuery(["transactions", page, rowsPerPage, filter], () => fetchTransactions(page, rowsPerPage, filter));
}

const fetchTotalCount = async (filter: TransactionFilter | null): Promise<number> => {
  let url = `${TRANSACTIONS_TOTAL}`;
  if (filter && filter.type) {
    url += `?filterType=${filter?.type}`;
  }

  const { data } = await apiService.get<number>(url);
  return data;
};

export function useTotalTransactions(filter: TransactionFilter | null) {
  return useQuery<number>(["transactionsTotalCount", filter], () => fetchTotalCount(filter));
}
 



