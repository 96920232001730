// src/services/apiService.ts
import axios, { AxiosInstance } from "axios";
import https from 'https';

function getTokenFromLocalStorage() {
    const token = window.localStorage.getItem('token');
    return token;
  }

const apiService: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  httpsAgent: new https.Agent({
    rejectUnauthorized: process.env.REACT_APP_DISABLE_CERTIFICATE_VERIFICATION !== 'true', // Disable certificate verification
  }),
});

// Add a request interceptor
apiService.interceptors.request.use((config) => {
      // Get the token from local storage
      const token = getTokenFromLocalStorage()?.slice(1,-1);
  
      // If the token exists, add the Authorization header
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
  
      return config;
    },
    (error) => {
      // Do something with the request error
      return Promise.reject(error);
    }
);

export default apiService;