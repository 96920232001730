import apiService from "../../services/apiService";
import { LOGIN } from "../../services/apiRoutes";
import { useMutation } from "react-query";
import { LoginToken } from "../types/loginToken";

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<LoginToken> => {
  const { data } = await apiService.post(LOGIN, { email, password });
  return data;
};

export function useLogin() {
  const { isLoading, mutateAsync } = useMutation(login);

  return { isLoggingIn: isLoading, login: mutateAsync };
}
