import { useMutation, useQueryClient } from "react-query";
import { updateOne } from "../../core/utils/crudUtils";
import { TransactionData, TransactionResponse } from "../../admin/types/transactions";
import { 
  TRANSACTIONS
} from "../../services/apiRoutes";
import apiService from "../../services/apiService";

const updateTransaction = async (transactionData: TransactionData): Promise<TransactionResponse> => {
  const { data } = await apiService.put(TRANSACTIONS, transactionData);
  return data;
};

export function useUpdateTransaction() {
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(updateTransaction, {
    onSuccess: (transaction: TransactionResponse) => {
      queryClient.setQueryData<TransactionResponse[]>(["transactions"], (oldUsers) =>
        updateOne(oldUsers, transaction)
      );
    },
  });

  return { isUpdating: isLoading, updateTransaction: mutateAsync };
}
